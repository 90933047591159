import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import React from 'react';
import Layout from './common/layout';

export const query = graphql`
    query PostsByID($id: String!) {
        mdx(id: { eq: $id }) {
            body
            frontmatter {
                title
                date(formatString: "MMMM D, YYYY")
                author
            }
        }
    }
`;

const DocsTemplate = ({ data }) => {
    const { frontmatter, body } = data.mdx;
    return (
        <Layout>
            <div className="container g-0 p-4">
                <div className="row g-0">
                    <div className="col-12">
                        <h3>{frontmatter.title}</h3>
                        <span className="ft-14 ft-theme-dark-grey">
                            {frontmatter.date} &nbsp;|&nbsp; {frontmatter.author}
                        </span>
                        <hr />
                    </div>
                    <div className="col-12">
                        <MDXRenderer>{body}</MDXRenderer>
                    </div>
                </div>
            </div>
        </Layout>
    );
};

export default DocsTemplate;
